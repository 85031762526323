
@media (min-width: 680px) {
  .tournament-wrapper {
    display: flex;
    gap: 2em;
    justify-content:center;
  }
  
  .tournament-stats{
  }
  
  .tournament-game {
  }
}

