.Home {
  margin-top: 20px; 
  text-align: center;
}

.Home h1 > *{
  vertical-align: middle;
}

.Home wired-button {
  position: relative;
  z-index: 1; 
  background: #fff;
}

.players-svg-container {
  position: absolute; 
  height: 0; 
  width: 100%; 
  padding: 0;
  padding-bottom: 100%;
  bottom: 10%; 
  z-index: -1;
  /* override this inline for aspect ratio other than square */
 }
 .players-svg-container svg {
  position: absolute; 
  height: 100%; 
  width: 100%; 
  left: 0; 
  top: 0;
  z-index: -1;
 }

 @keyframes player_shane {
  0%, 100% { transform: translate3d(0px, 0px, 0px); }
  10% { transform: translate3d(10px, 0px, 0px); }
  20% { transform: translate3d(0px, 10px, 0px)  scale(1.015); }
  30% { transform: translate3d(10px, 10px, 0px); }
  40% { transform: translate3d(10px, 0px, 0px) rotate(1deg);}
  50% { transform: translate3d(0px, -10px, 0px); }
  60% { transform: translate3d(-10px, -10px, 0px); }
  70% { transform: translate3d(-10px, 0px, 0px) scale(0.985); }
  80% { transform: translate3d(0px, 10px, 0px); }
  90% { transform: translate3d(-10px, 10px, 0px) rotate(-1deg);}
}

@keyframes virus_shane {
  0%, 100% { transform: translate3d(0px, 0px, 0px); }
  20% { transform: translate3d(20px, 0px, 0px) rotate(-1deg); }
  40% { transform: translate3d(20px, -20px, 0px); }
  60% { transform: translate3d(-20px, -20px, 0px) rotate(1deg); }
  80% { transform: translate3d(-20px, 0px, 0px); }
}

#red_user_0{
  transform-origin: 50% 50%;
  animation: 1s player_shane linear infinite;
  animation-delay: 0.25s;
}

#blue_user_0{
  transform-origin: 50% 50%;
  animation: 1s player_shane linear infinite;
}

#black_user_0{
  transform-origin: 50% 50%;
  animation: 1s player_shane linear infinite;
  animation-delay: 0.5s;
}

#red_virus_0{
  transform-origin: 100% 100%;
  animation: 1s virus_shane linear infinite;
  animation-delay: 0.1s;
}

#red_virus_1{
  transform-origin: 100% 100%;
  animation: 1s virus_shane linear infinite;
  animation-delay: 0.2s;
}

#red_virus_2{
  transform-origin: 100% 100%;
  animation: 1s virus_shane linear infinite;
  animation-delay: 0.3s;
}

#blue_virus_0{
  transform-origin: 100% 100%;
  animation: 1s virus_shane linear infinite;
  animation-delay: 0.4s;
}

#blue_virus_1{
  transform-origin: 100% 100%;
  animation: 1s virus_shane linear infinite;
  animation-delay: 0.5s;
}


