@font-face {
  font-family: 'Gloria Hallelujah';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Gloria Hallelujah'), local('GloriaHallelujah'), url('./assert/fonts/font.woff2.gif') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



body {
  background: #FDFDFD;
  font-family: 'Gloria Hallelujah', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.spiner{
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -38px 0 0 -38px; 
}

